import { createStore } from 'vuex'

export default createStore({
    state: {
        basePath: location.protocol + '//' + location.hostname
    },
    actions: {
        async Send (context, payload) {
            if ((process.env.NODE_ENV === 'development')) {
                console.log(payload)
            } else {
                const fields = {
                    fields: payload.fields
                }

                const formData = new FormData()
                formData.append('fields', JSON.stringify(fields))

                const response = await fetch(`/api/v1/sber/send/order/`, {
                    method: 'POST',
                    body: formData
                })

                const result = await response.json()
                console.log(result)

                if (typeof result.formUrl !== 'undefined') {
                    window.location.href = result.formUrl
                }
            }
        }
    }
})
