/*global $*/
/*eslint no-undef: "error"*/
export default class TextShow {
    classBlock = 'button-show--block'
    classButton = 'button-show--button'
    className = 'button-show--name'

    classBlockOpen = 'text-show-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let textTo = $(this).data('to')
            let textDo = $(this).data('do')
            $(this).closest(`.${self.classBlock}`).toggleClass(self.classBlockOpen)

            if ($(this).closest(`.${self.classBlock}`).hasClass(self.classBlockOpen)) {
                $(this).closest(`.${self.classBlock}`).find(`.${self.className}`).text(textDo)
            } else {
                $(this).closest(`.${self.classBlock}`).find(`.${self.className}`).text(textTo)
            }
        })
    }
}
