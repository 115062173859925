/*global $*/
/*eslint no-undef: "error"*/
export default class TabPrice {
    classTabs = 'tab-price--buttons'
    classTab = 'tab-price--button'

    classBlocks = 'tab-price--blocks'
    classBlock = 'tab-price--block'

    classBlockHidden = 'price__table-hidden'
    classTabActive = 'tab-active'

    constructor () {
        let self = this

        $(`.${self.classTab}`).click(function () {
            let block = $(this).data('block')

            $(this).closest(`.${self.classTabs}`).find(`.${self.classTab}`).removeClass(self.classTabActive)
            $(this).addClass(self.classTabActive)

            $(`.${self.classBlocks}`).find(`.${self.classBlock}`).addClass(self.classBlockHidden)
            $(`.${self.classBlocks}`).find(`.${self.classBlock}[data-block="${block}"]`).removeClass(self.classBlockHidden)
        })
    }
}
