/*global $*/
/*eslint no-undef: "error"*/
export default class ButtonProduct {
    classButton = 'button-product--button'
    classButtonActive = 'button-product--button-active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(`.${self.classButtonActive}`).removeClass(self.classButtonActive)
            $(this).addClass(self.classButtonActive)
        })
    }
}
