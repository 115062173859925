/*global $*/
/*eslint no-undef: "error"*/
export default class TabProducts {
    classButtons = 'products--buttons'
    classButton = 'products--button'
    classItems = 'products--items'
    classItem = 'products--item'

    classButtonActive = 'tab-active'
    classItemHidden = 'products__item-hidden'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')
            $(this).closest(`.${self.classButtons}`).find(`.${self.classButton}`).removeClass(self.classButtonActive)
            $(this).addClass(self.classButtonActive)

            $(`.${self.classItems}`).find(`.${self.classItem}`).addClass(self.classItemHidden)
            $(`.${self.classItems}`).find(`.${self.classItem}[data-block='${block}']`).removeClass(self.classItemHidden)
        })
    }
}
