/*global $*/
/*eslint no-undef: "error"*/
export default class Tab {
    classBlock = 'tab--block'
    classButton = 'tab--button'
    classItem = 'tab--item'
    clasSwiper = 'swiper'

    classSwiperChange = 'change'
    classButtonActive = 'tab-active'
    classItemHidden = 'swiper-slide-hidden'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')

            $(this).closest(`.${self.classBlock}`).find(`.${self.classButton}`).removeClass(self.classButtonActive)
            $(this).addClass(self.classButtonActive)

            $(this).closest(`.${self.classBlock}`).find(`.${self.classItem}`).addClass(self.classItemHidden)
            $(this).closest(`.${self.classBlock}`).find(`.${self.classItem}[data-block='${block}']`).removeClass(self.classItemHidden)

            $(this).closest(`.${self.classBlock}`).find(`.${self.clasSwiper}`).toggleClass(self.classSwiperChange)
        })
    }
}
