<template lang="pug">
.form-sber
  .form-sber__fields
    .form-sber__field
      label.input-text.input-text-black(:class="{'input-text-error': fieldError && v$.fieldOrder.$invalid}")
        .input-text__title(v-html="formFieldOrderTitle")
        input.input-text__input(
          type="number"
          v-model.number="fieldOrder"
          :placeholder="formFieldOrderPlaceholder"
        )

    .form-sber__field
      label.input-text.input-text-black(:class="{'input-text-error': fieldError && v$.fieldPrice.$invalid}")
        .input-text__title(v-html="formFieldPriceTitle")
        input.input-text__input(
          type="number"
          v-model.number="fieldPrice"
          :placeholder="formFieldPricePlaceholder"
        )

    .form-sber__field
      label.input-text.input-text-black
        .input-text__title(v-html="formFieldEmailTitle")
        input.input-text__input(
          type="email"
          v-model="fieldEmail"
          :placeholder="formFieldEmailPlaceholder"
        )

  .form-sber__button
    .button.button-yellow(@click="getSend()")
      .button__name Оплатить
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import {mapActions} from "vuex";
export default {
  name: 'AppSber',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formFieldOrderTitle",
    "formFieldOrderPlaceholder",
    "formFieldPriceTitle",
    "formFieldPricePlaceholder",
    "formFieldEmailTitle",
    "formFieldEmailPlaceholder",

    "formButtonName",
  ],

  data () {
    return {
      fieldOrder: '',
      fieldPrice: '',
      fieldEmail: '',
      fieldError: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        this.Send({
          fields: [
            {
              name: 'fieldOrder',
              value: this.fieldOrder
            },
            {
              name: 'fieldPrice',
              value: this.fieldPrice
            },
            {
              name: 'fieldEmail',
              value: this.fieldEmail
            }
          ]
        })

        this.fieldOrder = this.fieldPrice = this.fieldEmail = ''
      }
    }
  },

  validations () {
    return {
      fieldOrder: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(5)
      },

      fieldPrice: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(8)
      },
    }
  }
}
</script>
