/*global $*/
/*eslint no-undef: "error"*/
export default class TabService {
    classBlock = 'tab-service--block'
    classButton = 'tab-service--button'
    classItem = 'tab-service--item'

    classButtonActive = 'tab-active'
    classItemActive = 'service__block-active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')
            let objBlock = $(this).closest(`.${self.classBlock}`)

            objBlock.find(`.${self.classButton}`).removeClass(self.classButtonActive)
            $(this).addClass(self.classButtonActive)

            objBlock.find(`.${self.classItem}`).removeClass(self.classItemActive)
            $(this).closest(`.${self.classBlock}`).find(`.${self.classItem}[data-block='${block}']`).addClass(self.classItemActive)
        })
    }
}
